const G_time = {

  getCurrentTimestamp: function() {

    return parseInt(new Date().getTime() / 1000)

  },

  getCurrentDate: function() {
    var now = new Date();

    var year = now.getFullYear(); //年
    var month = now.getMonth() + 1; //月
    var day = now.getDate(); //日

    var hh = now.getHours(); //时
    var mm = now.getMinutes(); //分
    var ss = now.getSeconds(); //秒

    var clock = year + "-";

    if (month < 10)
      clock += "0";

    clock += month + "-";

    if (day < 10)
      clock += "0";

    clock += day + " ";

    if (hh < 10)
      clock += "0";

    clock += hh + ":";
    if (mm < 10) clock += '0';
    clock += mm + ":";

    if (ss < 10) clock += '0';
    clock += ss;
    return (clock);
  },

  ts2Date: (timestamp) => {
    let date = new Date(timestamp * 1000), //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      Y = date.getFullYear() + '-',
      M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-',
      D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ',
      h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':',
      m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ':',
      s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
    return Y + M + D + h + m + s;
  },

  date2ts: (_date) => {

    let date = new Date(_date)

    return date.getTime() / 1000

  },

  date2hours: (date) => {
    let hour = date.getHours(),
      min = date.getMinutes()

    hour = hour < 10 ? '0' + hour : hour
    min = min < 10 ? '0' + min : min
    return hour + ':' + min
  },

  format: (date, fmt='yyyy-MM-dd') => {
    var o = {
      "M+": date.getMonth() + 1, //月份 
      "d+": date.getDate(), //日 
      "h+": date.getHours(), //小时 
      "m+": date.getMinutes(), //分 
      "s+": date.getSeconds(), //秒 
      "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
      "S": date.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
      }
    }
    return fmt
  },
  /**
   * 获取日期之间所有日期
   * @param  {[type]} begin [description]
   * @param  {[type]} end   [description]
   * @return {[type]}       [description]
   */
  getAllDates: (begin, end) => {
    begin = G_time.format(new Date(begin))
    end = G_time.format(new Date(end))

    let arr = [],
      ab = begin.split('-'),
      ae = end.split('-'),
      db = new Date(),
      de = new Date(),
      unixDb,
      unixDe

    db.setUTCFullYear(ab[0], ab[1] - 1, ab[2])
    de.setUTCFullYear(ae[0], ae[1] - 1, ae[2])

    unixDb = db.getTime() - 24 * 60 * 60 * 1000
    unixDe = de.getTime() - 24 * 60 * 60 * 1000

    for (let k = unixDb; k <= unixDe;) {
      k += 24 * 60 * 60 * 1000
      arr.push(G_time.format(new Date(parseInt(k))))
    }
    return arr
  }

}

export default G_time
