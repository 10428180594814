<!-- 账户余额 -->
<template>
    <div class="flex jc-sb w100">
        <div class="flex ">
            <p class="cash_icon XYCenter"><img src="../../../assets/images/cash_icon.png" alt="" srcset=""></p>
            <p class="flexColumn balanceFather">
                <span class="balanceText">账户余额（元）</span>
                <span class="balance">{{ userInfo.ua_balance }}</span>
            </p>
        </div>
        <div class="flex YCenter "> <el-button type="primary" plain @click="jumpWithdrawalRecord">提现记录</el-button><el-button
                type="primary" v-if="showWithdrawalButton" @click="jumpWithdrawal">我要提现</el-button></div>
    </div>
</template>

<script>
export default {
    props: {
        userInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },
        showWithdrawalButton: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
        };
    },

    components: {},

    mounted() { },

    methods: {
        jumpWithdrawal() {
            this.$router.push({
                path: '/memberCenter/withdrawal'
            })
        },
        jumpWithdrawalRecord() {
            this.$router.push({
                path: '/memberCenter/withdrawalRecord'
            })
        }
    }
}

</script>
<style lang="scss" scoped>
.balanceFather {
    margin-left: 15px;

    .balanceText {
        margin-top: 5px;
        font-size: 15px;
        color: #686868;
    }

    .balance {
        margin-top: 15px;
        font-size: 25px;
        color: #f40;
    }


}

.cash_icon {
    width: 70px;
    height: 70px;
    background: #e4f1ff;
    border-radius: 10px;

    img {
        width: 40px;
    }
}
</style>