<!-- 个人中心 -->
<template>
    <div class="userCenter w100">
        <header class="header w100">
            <el-card class="box-card">
                <div class="flex">
                    <img :src="userInfo.u_avatar" class="userImg " alt="" srcset="">
                    <p class="flexColumn XCenter userName">
                        <span>{{ userInfo.u_nickname }} <span class="realName"
                                :style="{ background: userInfo.u_real_name ? '#409eff' : '#363636' }">{{
                                    userInfo.u_real_name ?
                                    '已实名' : '未实名'
                                }}</span></span>
                        <span class="realNameInfo">{{ userInfo.u_real_name +
                            userInfo.u_id_card }}</span>
                        <span class="m-t15">{{
                            userInfo.u_regist_time
                        }}</span>
                    </p>
                    <div class="welcome">欢迎回来！</div>
                </div>
                <div><el-button type="text" @click="showSetZfb">绑定收款支付宝</el-button></div>
            </el-card>
        </header>
        <main>
            <el-card class="box-card">
                <balanceCard :userInfo="userInfo" :showWithdrawalButton="true"></balanceCard>
                <div class="ZFBInfo">
                    <p class="text">当前收款账号</p>
                    <p class="ZFBNumber">支付宝账号：{{ userInfo.u_ali_account ? userInfo.u_ali_account : '暂无' }} </p>
                    <p class="ZFBName">支付宝实名：{{
                        userInfo.u_ali_realname ? userInfo.u_ali_realname : '暂无' }} <el-button type="text"
                            @click="showSetZfb">修改收款支付宝</el-button></p>


                </div>
            </el-card>
        </main>
        <setZFB :show="show" :userInfo="userInfo" @closeZFBFrom="closeZFBFrom"></setZFB>
    </div>
</template>

<script>
import setZFB from '../component/setZFB.vue';
import balanceCard from '../component/balanceCard.vue'
import G_time from "../../../tools/time";
export default {
    data() {
        return {
            userInfo: {},
            show: false
        };
    },

    components: {
        setZFB,
        balanceCard
    },

    mounted() {
        this.getUserinfo()
        console.log(this.$store.state)
    },

    methods: {
        closeZFBFrom() {
            this.show = false
            this.getUserinfo()
        },
        showSetZfb() {
            this.show = true
        },
        getUserinfo() {
            this.$axios
                .get("/api/user/getInfo")
                .then((res) => {
                    console.log(res);
                    res.data.u_regist_time = G_time.ts2Date(res.data.u_regist_time);
                    this.userInfo = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        toReaname() {

        }
    }
}

</script>
<style lang="scss" scoped>
.userCenter {
    margin: 0 20px 20px 0;

    .header {
        margin-bottom: 20px;

        .realName {
            // width: 100px;
            background: #757373;
            color: #fff;
            padding: 3px;
            font-size: 12px;
            border-radius: 3px;
        }

        .realNameInfo {
            font-size: 12px;
            color: #363636;
            margin: 5px 0 0px 0;
        }

        .userImg {
            width: 80px;
            border-radius: 20px;
            margin-right: 10px;
        }

        .userName {
            height: 80px;
        }

        .welcome {
            margin-left: 80px;
            font-size: 25px;
            height: 80px;
            line-height: 80px;
        }
    }

    .box-card {
        width: 60%;
    }





    .ZFBInfo {
        margin-top: 15px;

        .text {
            font-size: 18px;
            color: #686868;
        }

        .ZFBNumber {
            margin-top: 15px;
            font-size: 15px;
        }

        .ZFBName {
            font-size: 15px;
        }
    }

    .m-t15 {
        margin-top: 15px;
    }

}
</style>